import * as React from "react"
import { graphql } from "gatsby"

import PageHeader from "../components/pageheader"
import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../styles/basic.module.css"

const TnCPage = ({ data }) => {
    return (
        <Layout>
            {data?.wpPage?.blocks.map((item, i) => {
                switch (item?.name) {
                    case "acf/cover-main":
                        const coverMain = item?.coverMain
                        return (
                            <>
                                <Seo
                                    title={coverMain?.title}
                                    description={data?.wpPage?.seo?.metaDesc}
                                    date={data?.wpPage?.date}
                                    img={
                                        data?.wpPage?.seo?.opengraphImage
                                            ?.mediaItemUrl
                                    }
                                    imgHeight={
                                        data?.wpPage?.seo?.opengraphImage
                                            ?.mediaDetails?.height
                                    }
                                    imgWidth={
                                        data?.wpPage?.seo?.opengraphImage
                                            ?.mediaDetails?.width
                                    }
                                />
                                <PageHeader
                                    key={i}
                                    hasUnderline
                                    title={coverMain?.title}
                                    subTitle={coverMain?.text}
                                    image={coverMain?.img?.mediaItemUrl}
                                    breadcrumbs={[
                                        { label: coverMain?.title, link: null },
                                    ]}
                                />
                            </>
                        )
                }
            })}
            <div className="container mx-auto pt-4 pb-36">
                <div className={`mx-auto px-4 lg:px-0 ${styles.textContent}`}>
                    {data?.wpPage?.blocks.map((item, i) => {
                        switch (item?.name) {
                            case "core/heading":
                                return (
                                    <h2 key={i}>{item?.attributes?.content}</h2>
                                )
                            case "core/paragraph":
                                return (
                                    <p key={i}>{item?.attributes?.content}</p>
                                )
                        }
                    })}
                </div>
            </div>
        </Layout>
    )
}

export const query = graphql`
    {
        wpPage(slug: { eq: "terms-conditions" }) {
            blocks {
                name
                ... on WpAcfCoverMainBlock {
                    coverMain {
                        img {
                            altText
                            mediaItemUrl
                        }
                        title
                        text
                    }
                }
                ... on WpCoreParagraphBlock {
                    attributes {
                        ... on WpCoreParagraphBlockAttributes {
                            content
                        }
                    }
                }
                ... on WpCoreHeadingBlock {
                    attributes {
                        ... on WpCoreHeadingBlockAttributes {
                            content
                        }
                    }
                }
            }
            date
            seo {
                title
                metaDesc
                opengraphImage {
                    id
                    mediaItemUrl
                    mediaDetails {
                        height
                        width
                    }
                }
            }
        }
    }
`

export default TnCPage
